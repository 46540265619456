import React from 'react';
import { graphql, PageProps } from 'gatsby';
import { theme } from 'theme';
import { PageContextType } from 'types';
import {
  AllWpPostType,
  knowHowCaseStudiesType,
  WpPageTagType,
  WpPageType,
} from 'types/know-how-hub-page';

import Layout from 'components/templates/layout';
import PaginationLink from 'components/organisms/pagination-link';
import TagMenu from 'components/organisms/tag-menu';
import ArticleCard from 'components/molecules/article-card';
import LetsTalkBanner, {
  LetsTalkBannerType,
} from 'components/molecules/lets-talk-banner/lets-talk-banner';
import SimpleWrapper from 'components/molecules/simple-wrapper';
import Breadcrumb from 'components/atoms/breadcrumb';
import { StyledBreadcrumbWrapperCaseStudies } from 'components/atoms/breadcrumb/breadcrumb.styles';
import HidedTitle from 'components/atoms/hided-title';
import Typography from 'components/atoms/typography';

import useUniqueTags from 'hooks/use-unique-tags';

type DataProps = {
  commonComponents: {
    commonComponentsData: {
      letsTalkBanner: LetsTalkBannerType;
      pagesWithPagination: knowHowCaseStudiesType;
    };
  };
  allWpKnowHow: AllWpPostType;
  knowHowPage: WpPageType;
  allTagsWpKnowHow: WpPageTagType;
};

export const KnowHowCaseStudies = ({
  pageContext,
  data,
}: PageProps<DataProps, PageContextType>) => {
  const {
    commonComponents: {
      commonComponentsData: { letsTalkBanner, pagesWithPagination },
    },
    allWpKnowHow,
    allTagsWpKnowHow,
  } = data;
  const { currentPage, numPages, count, categoryUri } = pageContext;
  const allTags = allTagsWpKnowHow.nodes;
  const pageText = pagesWithPagination;
  const articleWidth = '350px';
  const uniqueTags = useUniqueTags(allTags);

  return (
    <Layout {...{ pageContext }}>
      <SimpleWrapper bgColor={theme.colors.gray0} padding={theme.gap.md}>
        <StyledBreadcrumbWrapperCaseStudies>
          <Breadcrumb {...{ pageContext }} />
        </StyledBreadcrumbWrapperCaseStudies>
        <HidedTitle html={pageContext?.name} />
      </SimpleWrapper>
      <SimpleWrapper margin={`${theme.gap.md} 0 ${theme.gap.md} 0`}>
        <Typography variant="title2" component="h2" html={pageContext?.name} />
      </SimpleWrapper>
      <SimpleWrapper>
        {allWpKnowHow.nodes.map((article) => (
          <ArticleCard
            tags={article.tags}
            categories={article.categories}
            date={article.date}
            margin={`${theme.gap.xs} ${theme.gap.xs} ${theme.gap.md} ${theme.gap.xs}`}
            maxWidth={articleWidth}
            title={article.title}
            postButton={{
              label: 'Czytaj dalej',
              ariaLabel: 'Czytaj dalej',
            }}
            uri={article.link}
            featuredImage={article.featuredImage}
          />
        ))}
      </SimpleWrapper>
      <PaginationLink
        currentPage={currentPage}
        numPages={numPages}
        text={pageText.pagination}
        allItem={count}
        basePath={`/know-how/${categoryUri}`}
      />
      <TagMenu
        categoryText={pageText.filterAll}
        tags={uniqueTags}
        categoryUri={`/know-how/${categoryUri}`}
      />
      <LetsTalkBanner {...letsTalkBanner} margin="0 auto" />
    </Layout>
  );
};

export const query = graphql`
  query KnowHowCaseStudiesPage(
    $id: String
    $commonComponentsSlug: String
    $limit: Int!
    $skip: Int!
    $categoryUri: String!
    $tagUri: String
  ) {
    knowHowPage: wpPage(id: { eq: $id }) {
      id
    }
    commonComponents: wpPage(slug: { eq: $commonComponentsSlug }) {
      commonComponentsData {
        pagesWithPagination {
          filterAll
          pagination {
            firstText
            lastText
            middleText
          }
        }
        letsTalkBanner {
          heading
          description
          linkButton {
            label
            icon {
              name
            }
            link {
              ariaLabel
              internal: wpInternal {
                ... on WpPage {
                  uri
                }
              }
              external {
                target
                url
              }
            }
          }
        }
      }
    }
    allWpKnowHow(
      sort: { order: DESC, fields: date }
      limit: $limit
      skip: $skip
      filter: {
        categories: { nodes: { elemMatch: { slug: { eq: $categoryUri } } } }
        tags: { nodes: { elemMatch: { slug: { eq: $tagUri } } } }
      }
    ) {
      nodes {
        featuredImage {
          node {
            ...WpImageFragment
          }
        }
        date(formatString: "DD.MM.YYYY", locale: "PL")
        categories {
          nodes {
            name
            slug
          }
        }
        tags {
          nodes {
            name
            slug
            uri
          }
        }
        id
        link
        title
        tags {
          nodes {
            name
            slug
            uri
          }
        }
        categories {
          nodes {
            name
            slug
          }
        }
        uri
      }
    }
    allTagsWpKnowHow: allWpKnowHow(
      sort: { order: DESC, fields: date }
      filter: { categories: { nodes: { elemMatch: { slug: { eq: $categoryUri } } } } }
    ) {
      nodes {
        tags {
          nodes {
            name
            uri
          }
        }
      }
    }
  }
`;

export default KnowHowCaseStudies;
